.add-modal {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000;

  .add-modal-inner {
    margin-left: 25%;
    margin-top: 25px;
    max-height: 90%;
    overflow-y: auto;
    padding: 25px;
    width: 50%;
  }

  .close-button {
    border: 0;
    float: right;
    font-size: 25px;
    margin-top: -15px;
    padding: 0;
  }

  form {
    margin-bottom: 0;
  }

  .form-group {
    border-bottom: 1px solid #c0c0c0;
    margin: 1em 0;
    padding-bottom: 1em;

    h3 {
      margin: 0;
    }

    .form-group-subhead {
      font-size: 0.7em;
      margin-bottom: 1em;
    }
  }

  .form-groups div:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  .form-actions {
    float: right;
    margin-top: 15px;

    button {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  label {
    font-weight: normal;
  }

  .form-input-label {
    display: inline-block;
    padding-top: 10px;
    vertical-align: top;
    width: 25%;

    &.required {
      font-weight: bold;
    }
  }

  input,
  select,
  textarea,
  .form-input-field {
    display: inline-block;
    width: 50%;

    img {
      display: block;
      width: 50%;
    }
  }

  input, select {
    height: 35px;
  }

  input[type="date"] {
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    padding-left: 10px;
  }

  select[disabled] {
    background: #fbfbfb;
    color: #848484;
  }

  button[type="submit"] {
    background: $link-color;

    &[disabled] {
      background: #d2d2d2;
      cursor: not-allowed;

      &:hover {
        border-color: #bbb;
      }
    }
  }

  .form-input-error {
    color: red;
  }

  .color-picker {
    display: inline-block;

    &-button {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      display: inline-block;
      padding: 5px;

      &-inner {
        border-radius: 2px;
        height: 14px;
        width: 36px;
      }
    }

    &-close-target {
      bottom: 0px;
      left: 0px;
      position: fixed;
      right: 0px;
      top: 0px;
    }
  }
}
