.bird-profile {
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 500;
  width: 430px;
  height: 220px;
  padding: 20px;
  background: #fff;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;

    span {
      cursor: pointer;
      padding: 5px;
      @extend %lato-light;
      text-align: center;
      font-size: 3rem;
      color: $medium-grey;
    }
  }

  .bird-profile-content {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .bird-photo,
  .bird-about-text {
    height: 100%;
    width: 50%;
  }

  .bird-photo {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .bird-about-text {
    overflow-y: auto;
    padding-left: 10px;

    p {
      line-height: 1.4;
      margin: 0;
    }
  }

  .bird-name {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  .bird-description-text {
    margin-top: 10px;

    &.no-stats {
      margin-top: 0;
    }
  }

  .bird-link {
    margin-top: 10px;
  }

  .bird-structured-info {
    margin-bottom: 5px;

    .structured-info-label {
      font-weight: bold;
      display: inline-block;
      float: left;
      padding-right: 10px;
      width: 60%;
    }

    .structured-info-value {
      display: inline-block;
      float: left;
      width: 40%;
    }
  }

  @media screen and (min-width: #{$breakpoints-width-large}px) {
    .bird-structured-info {
      .structured-info-label {
        display: block;
        float: none;
        margin-bottom: 2px;
        padding-right: 0;
        width: inherit;
      }

      .structured-info-value {
        display: block;
        float: none;
        width: inherit;
      }
    }
  }

  @media screen and (max-width: #{$breakpoints-width-small}px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1000;

    .close {
      top: 20px;
      right: 20px;
    }

    .bird-photo,
    .bird-about-text {
      display: block;
      width: 210px;
      margin: 0 auto;
    }

    .bird-photo {
      margin-top: 40px;
      height: 210px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .bird-about-text {
      margin-top: 20px;
      padding: 0;
      height: calc(50% - 40px);

      p {
        line-height: 1.4;
      }
    }
  }
}
