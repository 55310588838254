// helper classes
.uppercase {
  text-transform: uppercase;
}

.title-case {
  text-transform: title-case;
}

.bold {
  @extend %lato-bold;
}

.normal {
  @extend %lato;
}

.light {
  @extend %lato-light;
}

.hidden {
  display: none;
  visibility: hidden;
}

.hidden-sneaky {
  opacity: 0;
  z-index: -9999;
}
