.select-raptor-species {
  height: 45px;
  background: $grey-darker;
  padding: 0;

  @media screen
  and (max-width: #{$breakpoints-width-small}px) {
    height: 35px;
  }

  ul {
    list-style: none;
    height: 100%;
  }

  li {
    @extend %lato-bold;
    display: inline-block;
    position: relative;
    height: calc(100%);
    width: 20%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.5rem;

    @media screen
    and (max-width: #{$breakpoints-width-small}px) {
      font-size: 1.5rem;
      text-transform: none;
    }
  }

  .raptor-link {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    padding: 5px 0;
    color: #b2b2b2;
    background: $grey-darker;

    > * {
      vertical-align: middle;
    }

    &.active {
      color: #000;
      background: $grey;
    }

    &:not(:first-child) {
      margin-left: 5px;
    }

    .link-text {
      width: 75px;
      display: none;

      &.link-text-ospreys {
        vertical-align: middle;
      }

      &.link-text-hawks {
        vertical-align: bottom;
      }

      @media screen
      and (max-width: #{$breakpoints-width-small}px) {
        &.link-text-golden-eagles {
          height: 15px;
          width: 100px;
          margin-top: -7.5px;
        }
      }
    }
  }

  li.active {
    .link-text {
      display: inline-block;
    }
  }

  .link-icon {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-left: 1%;
    background-repeat: no-repeat;
    background-size: 30px 30px;

    &-ospreys {
      background-image: url('../img/raptor-icons_osprey-unselected.svg');
    }

    &-golden-eagles {
      background-image: url('../img/raptor-icons_eagle-unselected.svg');
    }

    &-hawks {
      background-image: url('../img/raptor-icons_hawk-unselected.svg');
      height: 24px;
      width: 33px;
    }

    &-falcons {
      background-image: url('../img/raptor-icons_falcon-unselected.svg');
    }

    &-vultures {
      background-image: url('../img/raptor-icons_vulture-unselected.svg');
    }
  }

  li.active {
    .link-icon {
      &-ospreys {
        background-image: url('../img/raptor-icons_osprey-selected.svg');
      }

      &-golden-eagles {
        background-image: url('../img/raptor-icons_eagle-selected.svg');
      }

      &-hawks {
        background-image: url('../img/raptor-icons_hawk-selected.svg');
      }

      &-falcons {
        background-image: url('../img/raptor-icons_falcon-selected.svg');
      }

      &-vultures {
        background-image: url('../img/raptor-icons_vulture-selected.svg');
      }
    }
  }
}
