.bird-toggle {
  overflow-y: auto;
  overflow-x: hidden;

  @media screen
  and (min-width: #{$breakpoints-width-small}px)
  and (max-width: #{$breakpoints-width-large}px) {
    // height: calc(100% - 105px);
  }

  @media screen and (max-width: #{$breakpoints-width-small}px) {
    // height: calc(100% - 105px);
    margin-top: 10px;
  }

  // mobile landscape
  @media screen
  and (max-width: #{$breakpoints-width-small}px)
  and (max-height: 420px) {
    height: 100%;
    margin-bottom: 20px;
    overflow-y: auto;
  }

  label {
    @extend %lato-light;
    display: inline-block;
    height: 35px;
    width: 100%;
    position: relative;
    font-size: 1.4rem;
    margin-bottom: 5px;
    text-align: left;

    input {
      margin-right: 10px;
    }

    .bird-name {
      line-height: 35px;
      vertical-align: middle;
    }

    .select-all {
      text-transform: uppercase;
    }
  }

  .bird-species-group {
    margin-bottom: 25px;

    .bird-species-name {
      margin: 5px 0;
      text-transform: uppercase;
    }
  }

  .bird-actions {
    display: inline-block;
    position: absolute;
    right: 0;

    .locate-btn {
      margin: 0;
      position: relative;
    }

    button {
      border-radius: 3px;
      font-size: 12px;
      height: 24px;
      letter-spacing: normal;
      padding: 5px;
      text-transform: uppercase;
      vertical-align: bottom;
    }
  }

  .delete-bird-button {
    background: #d00b0b;
    color: white;

    &:disabled,
    &[disabled] {
      background: #d8aaaa;
      cursor: not-allowed;
    }
  }

  .species-select-buttons {
    font-size: 12px;
    margin-bottom: 3px;
    text-align: right;

    button {
      border: 0;
      color: #1eaedb;
      height: auto;
      padding: 0;
    }

    &-label {
      margin-right: 5px;
    }
  }

  .show-archive-button-wrapper {
    margin: 25px 0;
    text-align: center;

    .show-archive-button {
      background: #f8f8f8;
      border-radius: 15px;
      font-size: 12px;
      height: auto;
      padding: 5px 20px;
      text-transform: uppercase;
    }
  }

  .bird-toggle-item {
    .bird-swatch {
      background: #ccc;
      display: inline-block;
      margin-right: 10px;
      height: 10px;
      width: 10px;
    }
  }
}
