@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file+"."+$type);
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url($file+"@2x."+$type);
      -webkit-background-size: $width $height;
      -moz-background-size: $width $height;
      -o-background-size: $width $height;
      background-size: $width $height;
    }
  }
}

// or for an SVG with a PNG fallback you can do:
/*****

background: url('./img/raptor-map-logo.png') no-repeat;
background: url('./img/raptor-map-logo.svg') no-repeat,
  linear-gradient(transparent, transparent);

******/
// tests for linear-gradient, if the browser doesn't have it then it won't
// try to render the SVG element.
// code credit: CSS Tricks:
