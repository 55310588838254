.mobile-nav {
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px 0 10px 0;

  li {
    display: inline-block;
    width: 33%;
    @extend %lato-bold;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    color: #b2b2b2;
    background: #dfe2e5;

    &.selected {
      color: #000;
      background: $light-grey;
    }

    a {
      text-decoration: none;
      background: #dfe2e5;
      color: #b2b2b2;
    }

    // for mobile nav elements
    &:nth-child(2n + 0),
    &:last-child {
      -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }
  }

  @media screen and (max-width: #{$breakpoints-width-small}px) {
  }

  // mobile landscape
  @media screen
		and (max-width: #{$breakpoints-width-small}px)
		and (max-height: 420px) {
    height: 40px;
  }
}
