// exported from variables.json
// by gulp-json-sass in gulpfile.babel.js
@import "variables-derived.scss";

// colors
$background-color: rgb(245, 245, 245);
$modal-overlay-color: rgba(51, 51, 51, 0.25);
$text-color: #454545;
$text-link-color: #207a9e;
$tile-border-color: rgb(225, 225, 225);

// greys
$light-grey: #f6f6f6;
$medium-grey: darken(#f6f6f6, 15%);
$dark-grey: #505149;
$grey: #efefef;
$grey-darker: #dbdbdb;
