.map-container {
  width: 100%;
  height: 100%;
  position: relative;

  @media screen and (max-width: #{$breakpoints-width-small}px) {
    height: calc(100% - 60px - 5px - 20px - 130px);
    margin: 10px 0 10px 0;
  }

  // mobile landscape
  @media screen
  and (max-width: #{$breakpoints-width-small}px)
  and (max-height: 420px) {
    height: 500px;
    overflow-y: auto;
  }
}

#map {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.leaflet-control-minimap {
  background-color: #fff;
}

.leaflet-label.nest-icon-label,
.raptor-icon-label {
  @extend %lato;
}

.mpg-ranch-boundary-label {
  @extend %lato-bold-italic;
  letter-spacing: 2px;
}

.mpg-ranch-boundary-label,
.raptor-icon-label {
  background: none;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  text-align: left;

  &:before {
    border: none;
  }
}

.raptor-icon-label {
  color: #333;
  padding: 0;
  border-radius: 0;
  line-height: 1;
  transition: opacity 300ms linear;
}

.map-ui {
  position: absolute;
  z-index: 100;
}

.map-zoom-btns {
  top: 20px;
  left: 20px;
  z-index: 2000;

  ul {
    list-style: none;
  }

  li {
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: #f5f5f5;
  }

  .zoom-in {
    background: url("../img/icons-zoomin.svg") no-repeat;

    &:active {
      background: url("../img/icons-zoomin-clicked.svg") no-repeat;
    }
  }

  .zoom-out {
    background: url("../img/icons-zoomout.svg") no-repeat;

    &:active {
      background: url("../img/icons-zoomout-clicked.svg") no-repeat;
    }
  }

  .zoom-world {
    background: url("../img/icons-worldzoom.svg") no-repeat;

    &:active {
      background: url("../img/icons-worldzoom-click.svg") no-repeat;
    }
  }
}

.basemap-toggle {
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f5f5f5;
  width: 75px;
  z-index: 2000;

  &-button {
    height: 75px;
    width: 75px;
  }

  &-label {
    padding: 3px;
    padding-top: 0;
    text-align: center;
  }

  @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
    top: 10px;
    right: 10px;
  }

  .satellite {
    @include background-image-retina(
      "../img/icons-basemap-sat",
      "png",
      75px,
      75px
    );

    &:active {
      @include background-image-retina(
        "../img/icons-basemap-sat-clicked",
        "png",
        75px,
        75px
      );
    }
  }

  .positron {
    @include background-image-retina(
      "../img/icons-basemap-pos",
      "png",
      75px,
      75px
    );

    &:active {
      @include background-image-retina(
        "../img/icons-basemap-pos-clicked",
        "png",
        75px,
        75px
      );
    }
  }
}
