// fill the browser with our glorious website
body,
html,
#root {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: hidden;

  // mobile landscape
  @media screen
    and (max-width: #{$breakpoints-width-small}px)
    and (max-height: 420px) {
    height: 450px;
    overflow-y: auto;
  }
}

body {
  // override Skeleton's strange line-height setting
  line-height: 1;
}

body,
svg text {
  @extend %lato-light;

  // Note: below are very loose responsive breakpoints;
  // tighten up as needed for your application.

  /* ----------- Phones ----------- */
  @media screen
    and (max-width: #{$breakpoints-width-small}px) {
    // font-size: 16px;
  }

  /* ----------- Tablets ----------- */
  @media screen
    and (min-width: #{$breakpoints-width-small}px)
    and (max-width: #{$breakpoints-width-large}px) {
    font-size: 12px;
  }

  /* ----------- Desktops ----------- */
  @media screen
    and (min-width: #{$breakpoints-width-large}px) {
    // font-size: 24px;
  }
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.4em;
}
h3 {
  font-size: 1em;
}
h4 {
  font-size: 0.67em;
}
h5 {
  font-size: 0.5em;
}
h6 {
  font-size: 0.35em;
}

h2 {
  margin-bottom: 10px;
}

a {
  &:link,
  &:over,
  &:hover,
  &:active {
    color: $text-link-color;
  }
}

// tmp styles for positioning boxes
div {
  // border: 1px solid orange;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  position: relative;
  margin: 0;
  padding: 0;
}

.container {
  padding: #{$app-containerPadding}px;

  @media screen and (max-width: #{$breakpoints-width-small}px) {
    padding: 10px;
  }
}

.container.mobile {
  & > .row {
    display: flex;
    flex-direction: column;
  }

  .logo {
    flex: 0 0 auto;
  }

  .mobile-nav {
    flex: 0 0 auto;
  }

  .select-raptor-species {
    flex: 0 1 auto;
  }

  .bird-toggle {
    flex: 1 1 auto;
  }

  .map-container {
    flex: 1 1 auto;
  }

  .date-slider {
    flex: 0 1 auto;
    height: 90px;
  }

  .hidden-sneaky {
    /* 
     * Try to remove date slider from view and flex flow without breaking the
     * line chart, which resizes depending on the width of this element.
     */
    position: absolute;
    left: 10px;
    right: 10px;
    width: auto;
  }

  .axis.y {
    opacity: 0;
  }
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

.four-fifths-height {
  height: 80%;
}

.one-fifth-height {
  height: 20%;
}

.one-tenth-height {
  height: 10%;
}

.nine-tenths-height {
  height: 90%;
}

.row.one-fifth-height {
  height: 150px;
}

.row.four-fifths-height {
  height: calc(100% - 20px - 150px);
  margin-bottom: 20px;
}

.row.logos {
  height: 50px;
  margin-top: 20px;
}

.row.menu-height {
  height: calc(100% - 20px - 150px - 70px);
  margin-bottom: 20px;
}

.row.menu-full-height {
  height: calc(100% - 70px);
  margin-bottom: 20px;
}

.menu-column {
  width: 315px;
}

@media screen
    and (min-width: #{$breakpoints-width-small}px) {
  .map-column {
    width: calc(100% - 1.5% - 315px) !important;
  }

  .menu-column {
    width: 315px !important;
  }
}

// zoom to bird / nest buttons
.locate-btn {
  display: inline-block;
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 0 5px;
  top: 50%;
  right: 0;
  margin-top: -17px;
  background: url("../img/locate-off.svg") no-repeat;

  &.disabled {
    pointer-events: none;
    width: 26px;
    height: 26px;
    margin-top: -13px;
    background: url("../img/locate-disabled.svg") no-repeat;
  }

  &:active {
    background: url("../img/locate-clicked.svg") no-repeat;
  }

  @media screen
    and (min-width: #{$breakpoints-width-small}px)
    and (max-width: #{$breakpoints-width-large}px) {
    width: 25px;
    height: 25px;
    margin-top: -12.5px;

    &.disabled {
      width: 19px;
      height: 19px;
      margin-top: -9.5px;
    }
  }
}

.logo {
  height: 51px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding: 0;

  header {
    position: relative;
    top: 10px;
    width: 315px;
    max-width: 315px;
    height: 40px;
    display: block;
    // background-size: 100% auto;
    background: url("../img/raptor-map-logo.png") no-repeat;
    background: url("../img/raptor-map-logo.svg") no-repeat,
      linear-gradient(transparent, transparent);

    @media screen
      and (max-width: #{$breakpoints-width-small}px) {
      height: 30px;
    }
  }

  &.logo-right {
    position: relative;
    margin-bottom: 0;
    height: 51px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    a {
      height: 100%;
      width: 100%;
      display: block;
    }

    .logo-raptor-view,
    .logo-mpg-ranch {
      height: 100%;
      display: inline-block;
    }

    .logo-raptor-view {
      width: 120px;
      vertical-align: top;
    }

    .logo-mpg-ranch {
      width: 84px;
      margin-left: 10px;
      vertical-align: top;
    }
  }

  @media screen
    and (min-width: #{$breakpoints-width-small}px)
    and (max-width: #{$breakpoints-width-large}px) {
    // height: 40px;
  }

  @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
    padding: 0;
    height: 40px;
    margin-bottom: 10px;
  }

  // mobile landscape
  @media screen
    and (max-width: #{$breakpoints-width-small}px)
    and (max-height: 420px) {
    height: 40px;
  }
}

// for component boxes
.ui-box {
  -webkit-box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.25);

  background-color: $grey;
  padding: 10px 20px;

  @media screen and (max-width: #{$breakpoints-width-small}px) {
    padding: 10px;
  }
}
