.date-slider {
  width: 100%;
  height: 100%;

  @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
    height: 110px;
    padding: 5px;
  }

  // mobile landscape
  @media screen
  and (max-width: #{$breakpoints-width-small}px)
  and (max-height: 420px) {
    height: 200px - 40px;
    margin: 20px 0 10px 0;
  }

  .left,
  .right {
    height: 100%;
    display: inline-block;
    float: left;
  }

  .left {
    width: 100px;

    @media screen and (max-width: #{$breakpoints-width-large}px) {
      width: 50px;
    }
  }

  .right {
    width: calc(100% - 100px);

    @media screen and (max-width: #{$breakpoints-width-large}px) {
      width: calc(100% - 50px);
    }
  }

  .slider-title,
  .slider-buttons {
    width: 100%;
    display: block;
  }

  .slider-title {
    height: 19%;

    h2,
    p {
      display: inline;
    }

    h2 {
      margin-right: 10px;
    }
  }

  .slider-buttons {
    height: 80%;
    position: relative;

    @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
      height: 100%;
    }
  }

  button.slider-animation {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -35px 0 0 -25px;
    padding: 0;
    border: none;

    &.play {
      background: url("../img/play-off.svg") no-repeat;
    }

    &.pause {
      background: url("../img/pause-off.svg") no-repeat;
    }

    @media screen
    and (min-width: #{$breakpoints-width-small}px)
    and (max-width: #{$breakpoints-width-large}px) {
      width: 25px;
      height: 25px;
      margin: -14.5px 0 0 -14.5px;
    }

    @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
      width: 35px;
      height: 35px;
      margin: -23.5px 0 0 -17.5px;
    }
  }

  .slider-container {
    width: 100%;
    height: 100%;
  }

  .axis {
    fill: $dark-grey;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .tick > line {
    stroke: $dark-grey;
    stroke-width: 1;
    transform: translateY(-5px);
  }

  .tick > text {
    transform: translateY(-5px);
    font-size: 12px;
  }

  .halo {
    stroke: $dark-grey;
    stroke-width: 1px;
    stroke-linecap: square;
  }

  .handle {
    &:hover {
      cursor: grab;
    }
  }

  .handle path {
    stroke: $dark-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    pointer-events: none;
  }

  .handle text {
    fill: #333;
    text-align: center;
    font-size: 15px;
  }

  @media screen
  and (min-width: #{$breakpoints-width-small}px)
  and (max-width: #{$breakpoints-width-large}px) {
    .tick > text {
      font-size: 10px;
    }
    .handle > text {
      font-size: 12px;
    }
  }

  @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
    .tick > text {
      font-size: 10px;
    }
    .handle > text {
      font-size: 12px;
    }
  }
}
