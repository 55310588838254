.nest-locations {
  height: 100%;
  width: 100%;

  @media screen and (max-width: #{$breakpoints-width-xsmall}px) {
    margin: 10px 0 10px 0;
    height: calc(100% - 40px - 30px - 35px);
  }

  // mobile landscape
  @media screen
  and (max-width: #{$breakpoints-width-small}px)
  and (max-height: 420px) {
    height: calc(100% - 40px - 40px - 35px);
    margin: 20px 0 10px 0;
  }

  h2 {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
  }

  li {
    height: 35px;
    margin-bottom: 0;
    line-height: 35px;
    vertical-align: middle;

    .nest-cam-link {
      font-size: 1.1rem;
      padding-left: 10px;

      a {
        text-decoration: none;
      }
    }

    @media screen
    and (min-width: #{$breakpoints-width-small}px)
    and (max-width: #{$breakpoints-width-large}px) {
      font-size: 10px;
    }
  }
}
