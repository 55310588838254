// Google Fonts imported in index.html;
// Replace these SASS placeholders as necessary with imported fonts
%better-helvetica {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

%better-helvetica-bold {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 800;
}

%lato {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

%lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

%lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

%lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}
